<!--
 * @Author: lijing
 * @Date: 2023-07-26 16:24:17
 * @LastEditors: lijing
 * @LastEditTime: 2023-08-09 14:31:40
 * @Description: 
-->
<template>
  <div>
    <!--主体内容-->
    <div class="content">
      <div class="back">
        <el-button type="primary" class="back" @click="$router.go(-1)">返回</el-button>
      </div>
      <div class="titleName">
        停车场信息
      </div>
      <div class="parkInfo">
        <div class="item">车场名称：<span>{{ infomation.parkName || '-' }}</span></div>
        <div class="item">车场编号: <span>{{ infomation.parkCode || '-' }}</span></div>

        <div class="item" v-if="infomation.slaveRelation != 1">泊位数：<span>{{ tableData ? tableData.length : '0' }}个</span></div>
        <div class="item" v-if="infomation.slaveRelation != 1">设备品牌：<span>{{ '爱泊车' }}</span></div>

        <div class="item" v-if="infomation.slaveRelation == 1">子车场：<span>{{ tableData ? tableData.length : '0' }}个</span></div>

        <div class="item">区域：<span>{{ infomation.areaName || '-' }}</span></div>
        <div class="item">车场设备类型：<span>{{ infomation.parkEquipmentTypeName || '-' }}</span></div>
        <div class="item">商户名称：<span>{{ infomation.operationName || '-' }}</span></div>
        <div class="item">经纬度：<span>{{ infomation.longitude || '-' }},{{ infomation.latitude || '-' }}</span></div>
        <div class="item">地址：<span>{{ infomation.parkAddress || '-' }}</span></div>

        <div class="item" v-if="infomation.slaveRelation != 1">关联父车场：<span>{{ infomation.parentParkName || '-' }}</span></div>

      </div>
      <div class="titleName" v-if="infomation.slaveRelation == 1">
        子车场列表：<span>共计{{ tableData ? tableData.length : 0 }}个子车场</span>
      </div>
      <div class="titleName" v-else>
        泊位信息：<span>共计{{ tableData ? tableData.length : 0 }}个泊位</span>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <!-- 父车场 -->
        <el-table
            v-if="infomation.slaveRelation == 1"
            header-cell-class-name="header-call-style"
            border
            
            :data="tableData"
            style="width: 100%"
            min-height="400"
        >
          <el-table-column
              :prop="item.prop"
              :label="item.label"
              align="center"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!-- 独立车场/子车场 -->
        <el-table :data="tableData" v-else>
          <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
          <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
          <el-table-column prop="equipmentCode" label="设备编码" align="center"></el-table-column>
          <el-table-column prop="equipmentTypeName" :label="$t('searchModule.Equipment_type')" align="center"></el-table-column>
          <el-table-column prop="parkCarCount" label="有无车辆" align="center" :formatter="parkCarCount"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  components: {
  },
  data() {
    return {
      infomation: [],
      tableData: [],
      tableCols: [
        {
          prop: 'parkCode',
          label: this.$t("list.Yard_code"),
          width: ''
        },
        {
          prop: 'parkName',
          label: this.$t("list.park_name"),
          width: ''
        },
        {
          prop: 'parkEquipmentTypeName',
          label: '车场设备类型',
          width: ''
        }
      ]
    };
  },
  watch: {},
  computed: {
    // 有无车辆
    parkCarCount() {
      return (val) => {
        switch (val.parkCarCount) {
          case 0:
            return '无车'
          default:
            return '有车'
        }
      };
    }
  },
  methods: {
    // 获取详情信息
    getinfoData(parkCode) {
      this.$axios
      .get("/acb/2.0/syncAlphaPark/detail", {
        data: {
          parkCode: parkCode,
        },
      })
      .then((res) => {
        if (res.state == 0) {
          this.infomation = res.value;
          this.tableData = res.value.berthList || res.value.childParkList;
        } else {
          this.$alert(res.desc);
        }
      });
    }
  },
  created() {
  },
  mounted() {
    let { parkCode } = this.$route.query;
    if (parkCode) {
      console.log('1111111111111111')
      this.getinfoData(parkCode)
    }
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;
  background: #fff;
  padding: 20px;

  .parkInfo {
    height: 115px;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    padding: 20px 20px;
    .item {
      font-weight: 800;
      width: 25%;
      span {
        display: inline-block;
        font-weight: 500;
      }
    }
  }

  .back {
    text-align: right;
  }

  .titleName {
    font-weight: 800;
    color: #3F9EFF;
    margin-bottom: 10px;
    span {
      color: #000;
    }
  }
  
  .tableWrapper {
    .el-table {
      width: 100%!important;
    }
  }
}
</style>

